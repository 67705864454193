<template>
    <DialogWrapper :show="show">
        <DialogCentered
            :show="show"
            :divider="false"
            class="newsletter-confirmation-modal"
            @close="closeModal()"
            @button-trailing-click="closeModal()"
        >
            <template #icon-trailing>
                <Icon :icon="Close" />
            </template>
            <NewsletterConfirmationInfo @close-modal="closeModal()" />
        </DialogCentered>
    </DialogWrapper>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import DialogWrapper from '@molecules/DialogWrapper/DialogWrapper';
import NewsletterConfirmationInfo from '@molecules/NewsletterConfirmationInfo/NewsletterConfirmationInfo';

import { DialogCentered } from '@modivo-ui/components/DialogCentered/v1';

import { Icon } from '@modivo-ui/components/Icon/v1';
import { Close } from '@modivo-ui/icons/v2/navigation';

const { mapActions: mapCustomerActions } = createNamespacedHelpers('customer');

export default {
    name: 'NewsletterConfirmationModal',

    components: {
        DialogWrapper,
        DialogCentered,
        NewsletterConfirmationInfo,
        Icon,
    },

    props: {
        show: {
            type: Boolean,
            required: true,
        },
    },

    beforeCreate() {
        this.Close = Close;
    },

    methods: {
        ...mapCustomerActions(['setShouldOpenModalNewsletterConfirmation']),

        closeModal() {
            this.setShouldOpenModalNewsletterConfirmation(false);
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
.newsletter-confirmation-modal {
    @apply fixed top-ui-0 bottom-ui-0 left-ui-0 right-ui-0 z-ui-2;

    &:deep() {
        .content {
            @apply flex-col items-center p-ui-4;
        }

        .dialog {
            @apply text-center z-5;
        }
    }

    @screen lg {
        &:deep() {
            .content {
                @apply p-ui-6;
            }
        }
    }
}
</style>
