<template>
    <div class="newsletter-confirmation-info">
        <Icon :icon="Letter" width="64" height="64" />
        <HeaderText :tag="HEADER_TAG" :size="headerSize" class="header">
            {{ $t('You are only one more step away from a discount!') }}
        </HeaderText>
        <BodyText class="text">
            {{
                $t(
                    // eslint-disable-next-line max-len
                    'We sent you an email with a link to confirm your Newsletter subscription. Click on it and we will send you a -15% discount on your purchases.'
                )
            }}
        </BodyText>
        <component
            :is="confirmButton.component"
            v-if="showConfirmButton || isSniperLinkEnabled"
            :variant="confirmButton.variant"
            :href="sniperLink"
            target="_blank"
            class="button"
            @click.native="buttonClick()"
        >
            {{ buttonText }}
        </component>
        <BodyText
            v-if="newsletterConfirmationEmail.length"
            :align="BODY_TEXT_CENTER"
            class="bottom-text"
        >
            <span v-html="bottomText" />
        </BodyText>
    </div>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';

import {
    CONFIRM_SAVING_MODAL_GMAIL_VIEW,
    CONFIRM_SAVING_MODAL_YAHOO_VIEW,
    CONFIRM_SAVING_MODAL_GMAIL_CLICK,
    CONFIRM_SAVING_MODAL_YAHOO_CLICK,
    CONFIRM_SAVING_MODAL_GMAIL_CLOSE,
    CONFIRM_SAVING_MODAL_YAHOO_CLOSE,
} from '@analytics-modules/client/types/Events';
import { MODULE_NAME as CLIENT_MODULE_NAME } from '@analytics-modules/client/meta';

import Letter from '@static/icons/illustration/letter.svg?inline';

import {
    isSniperLinkEnabled,
    getSniperLink,
    getSniperLinkText,
    isGoogleDomain,
} from '@assets/sniper-link';

import {
    HeaderText,
    HEADER_TEXT_TAGS,
    HEADER_TEXT_SIZES,
} from '@modivo-ui/components/HeaderText/v1';
import { BodyText, BODY_TEXT_ALIGNS } from '@modivo-ui/components/BodyText/v1';
import { Icon } from '@modivo-ui/components/Icon/v1';
import { ButtonLarge, BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';
import { ButtonSmall, BUTTON_SMALL_VARIANTS } from '@modivo-ui/components/ButtonSmall/v1';
import { EMAIL } from '@localeConfig/keys';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const { mapState: mapCustomerState, mapActions: mapCustomerActions } = createNamespacedHelpers(
    'customer'
);

export default {
    name: 'NewsletterConfirmationInfo',

    components: {
        HeaderText,
        BodyText,
        Icon,
        ButtonLarge,
        ButtonSmall,
    },

    props: {
        showConfirmButton: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapConfigGetters(['localeConfigByKey']),
        ...mapCustomerState(['newsletterConfirmationEmail']),

        headerSize() {
            const { M, L } = HEADER_TEXT_SIZES;

            return this.isMobile ? M : L;
        },

        confirmButton() {
            const { PRIMARY } = BUTTON_LARGE_VARIANTS;
            const { PRIMARY: SMALL_PRIMARY } = BUTTON_SMALL_VARIANTS;

            if (this.isMobile) {
                return {
                    component: 'ButtonSmall',
                    variant: SMALL_PRIMARY,
                };
            }

            return {
                component: 'ButtonLarge',
                variant: PRIMARY,
            };
        },

        isSniperLinkEnabled() {
            return isSniperLinkEnabled(this.newsletterConfirmationEmail);
        },

        bottomText() {
            return this.$t('The email was sent to {email}.', {
                email: this.newsletterConfirmationEmail,
            });
        },

        buttonText() {
            return this.isSniperLinkEnabled
                ? getSniperLinkText(this, this.newsletterConfirmationEmail)
                : this.$t('I understand');
        },

        sniperLink() {
            return this.isSniperLinkEnabled
                ? getSniperLink(this.newsletterConfirmationEmail, this.senderEmailPerLocale)
                : null;
        },

        isEmailInGoogleDomain() {
            return isGoogleDomain(this.newsletterConfirmationEmail);
        },

        senderEmailPerLocale() {
            return this.localeConfigByKey(EMAIL);
        },
    },

    beforeCreate() {
        this.Letter = Letter;
        this.HEADER_TAG = HEADER_TEXT_TAGS.STRONG;
        this.BUTTON_LARGE_VARIANT = BUTTON_LARGE_VARIANTS.PRIMARY;
        this.BODY_TEXT_CENTER = BODY_TEXT_ALIGNS.CENTER;
    },

    beforeDestroy() {
        if (this.isSniperLinkEnabled) {
            this.$analytics.moduleEmit(
                CLIENT_MODULE_NAME,
                this.isEmailInGoogleDomain
                    ? CONFIRM_SAVING_MODAL_GMAIL_CLOSE
                    : CONFIRM_SAVING_MODAL_YAHOO_CLOSE
            );
        }

        this.setNewsletterConfirmationEmail('');
    },

    mounted() {
        if (this.isSniperLinkEnabled) {
            this.$analytics.moduleEmit(
                CLIENT_MODULE_NAME,
                this.isEmailInGoogleDomain
                    ? CONFIRM_SAVING_MODAL_GMAIL_VIEW
                    : CONFIRM_SAVING_MODAL_YAHOO_VIEW
            );
        }
    },

    methods: {
        ...mapCustomerActions(['setNewsletterConfirmationEmail']),

        buttonClick() {
            if (this.isSniperLinkEnabled) {
                this.$analytics.moduleEmit(
                    CLIENT_MODULE_NAME,
                    this.isEmailInGoogleDomain
                        ? CONFIRM_SAVING_MODAL_GMAIL_CLICK
                        : CONFIRM_SAVING_MODAL_YAHOO_CLICK
                );
            } else {
                this.$emit('close-modal');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.newsletter-confirmation-info {
    @apply flex flex-col items-center;

    .header {
        @apply mt-ui-4 text-center;
    }

    .text {
        @apply mt-ui-1 text-center;
    }

    .button {
        @apply mt-ui-4;
    }

    .bottom-text {
        @apply mt-ui-4 mb-ui-6 text-ui-swap-gray600 text-ui-12 leading-ui-18;
    }

    @screen lg {
        .header {
            @apply mt-ui-6;
        }

        .text {
            @apply mt-ui-2 text-ui-18 leading-ui-24;
        }

        .button {
            @apply mt-ui-6;
        }

        .bottom-text {
            @apply mt-ui-6;
        }
    }
}
</style>
